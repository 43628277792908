import abi from "@ohgeez/fsushi/abis/FSushiKitchen.json";
import deployment from "@ohgeez/fsushi/deployments/mainnet/FSushiKitchen.json";
import { FSushiKitchen } from "@ohgeez/fsushi/typechain-types/contracts/FSushiKitchen";
import { useEthers } from "@usedapp/core";
import { Contract } from "ethers";

const useFSushiKitchen = () => {
    const { library } = useEthers();

    const getWeightPoints = async (pid: number) => {
        if (library) {
            const kitchen = new Contract(deployment.address, abi, library) as FSushiKitchen;
            return kitchen.weightPoints(pid);
        }
    };

    const getRelativeWeight = async (pid: number) => {
        if (library) {
            const kitchen = new Contract(deployment.address, abi, library) as FSushiKitchen;
            return kitchen.relativeWeight(pid);
        }
    };

    const getRelativeWeightAt = async (pid: number, timestamp: number) => {
        if (library) {
            const kitchen = new Contract(deployment.address, abi, library) as FSushiKitchen;
            return kitchen.relativeWeightAt(pid, timestamp);
        }
    };

    return {
        getWeightPoints,
        getRelativeWeight,
        getRelativeWeightAt,
    };
};

export default useFSushiKitchen;
