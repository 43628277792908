import React, { useState } from "react";

import { useEthers } from "@usedapp/core";
import useAsyncEffect from "use-async-effect";

import useFSushiBill, { PastRewards } from "../hooks/useFSushiBill";
import { Pool } from "../hooks/useSushiSwapPools";
import { formatAmount, toTimestamp } from "../utils";
import Loading from "./Loading";

const HistoryOfRewards = (props: { pool: Pool }) => {
    const { library } = useEthers();
    const { getPastRewards } = useFSushiBill();
    const [rewards, setRewards] = useState<PastRewards[]>();

    useAsyncEffect(async () => {
        if (library) {
            setRewards(await getPastRewards(props.pool.id));
        }
    }, [library]);

    return (
        <div className={"w-full"}>
            {rewards ? (
                <>
                    <table className="w-full">
                        <thead>
                            <tr>
                                <th className={"text-left"}>Week&apos;s End</th>
                                <th className={"text-right"}>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rewards.map(reward => (
                                <Row reward={reward} key={reward.week} />
                            ))}
                        </tbody>
                    </table>
                    <div className={"bubble mt-4"}>
                        <div className={"caption"}>
                            <b>Amount</b> means allocated fSUSHI, not actually claimed fSUSHI.
                        </div>
                    </div>
                </>
            ) : (
                <Loading />
            )}
        </div>
    );
};

const Row = (props: { reward: PastRewards }) => {
    const date = new Date(toTimestamp(props.reward.week + 1) * 1000);
    return (
        <tr>
            <td className={"text-left"}>{date.toLocaleString()}</td>
            <td className={"text-right"}>{formatAmount(props.reward.amount)}</td>
        </tr>
    );
};

export default HistoryOfRewards;
