import React, { ChangeEvent, useEffect, useState } from "react";

import { DepositEvent } from "@ohgeez/fsushi/typechain-types/contracts/FSushiBar";
import { useEthers } from "@usedapp/core";
import { BigNumber } from "ethers";

import useERC2612 from "../hooks/useERC2612";
import { FSUSHI } from "../hooks/useFSushi";
import useFSushiBar, { XFSUSHI } from "../hooks/useFSushiBar";
import useLink from "../hooks/useLink";
import useUpdater from "../hooks/useUpdater";
import { formatAmount, formatPercentage } from "../utils";
import ErrorMessage from "./ErrorMessage";
import TokenAmountInput from "./TokenAmountInput";

const MAX_WEEKS = 104;

let handle: number;
const DepositFSushi = () => {
    const { account } = useEthers();
    const { lastUpdated, update } = useUpdater();
    const { previewDeposit, depositSigned, depositing } = useFSushiBar();
    const [amount, setAmount] = useState<BigNumber>();
    const [weeks, setWeeks] = useState("");
    const [preview, setPreview] = useState<{ shares: BigNumber; totalSupply: BigNumber }>();
    const [error, setError] = useState("");
    const [event, setEvent] = useState<DepositEvent>();
    const { sign } = useERC2612(FSUSHI, "Flash Sushi Token", "1");

    useEffect(() => {
        setWeeks("");
        setAmount(undefined);
    }, [account, lastUpdated]);

    useEffect(() => {
        if (amount && weeks) {
            if (handle) clearTimeout(handle);
            handle = setTimeout(async () => {
                setPreview(await previewDeposit(amount, parseInt(weeks)));
            }, 500) as unknown as number;
        } else {
            setPreview(undefined);
        }
    }, [amount, weeks]);

    const onChangeWeek = (event: ChangeEvent<HTMLInputElement>) => {
        setError("");
        try {
            const weeks = parseInt(event.target.value.trim());
            if (weeks < 1 || weeks > MAX_WEEKS) {
                setError("Invalid number of weeks");
            }
            setWeeks(event.target.value.trim());
        } catch (e) {
            if (e instanceof Error) setError(e.message);
        }
    };

    const onDeposit = event
        ? useLink("https://etherscan.io/tx/" + event.transactionHash)
        : async (amount: BigNumber) => {
              setError("");
              if (weeks.trim().length === 0) {
                  setError("Please enter the number of weeks");
                  return;
              } else {
                  try {
                      const deadline = Math.floor(Date.now() / 1000) + 60 * 10; // 10 mins from now
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      const signature = await sign(XFSUSHI, amount, deadline);
                      if (signature) {
                          setEvent(
                              await depositSigned(
                                  amount,
                                  parseInt(weeks.trim()),
                                  deadline,
                                  signature.v,
                                  signature.r,
                                  signature.s
                              )
                          );
                          update();
                      }
                  } catch (e) {
                      if (e instanceof Error) setError(e.message);
                  }
              }
          };

    return (
        <div>
            <div className={"md:flex flex-row items-end mb-2"}>
                <div className={"relative w-full md:w-28 mr-2 mb-2"}>
                    <input
                        type="number"
                        max={MAX_WEEKS}
                        value={weeks}
                        disabled={!account}
                        onChange={onChangeWeek}
                        placeholder="0"
                        className={"w-full"}
                    />
                    <div className={"absolute right-4 top-2.5 pl-1"}>
                        {account ? <div className={"quote bg-white"}>Weeks</div> : <div className={"quote"}>Weeks</div>}
                    </div>
                </div>
                <TokenAmountInput
                    disabled={!!error || depositing}
                    className={"md:flex-4"}
                    title={"Deposit"}
                    onAmountChange={setAmount}
                    onSubmit={onDeposit}
                />
            </div>
            <div className={"bubble"}>
                <div className={"caption"}>
                    {preview ? (
                        <span>
                            You&apos;ll receive <b>{formatAmount(preview.shares)} xfSUSHI</b>,{" "}
                            {formatPercentage(preview.shares, preview.totalSupply)} of total shares.
                        </span>
                    ) : (
                        <span>
                            Input week from <b>1</b> to <b>{MAX_WEEKS}</b> and amount you want to stake.
                        </span>
                    )}
                </div>
            </div>
            <ErrorMessage text={error} />
        </div>
    );
};

export default DepositFSushi;
