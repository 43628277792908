import React, { useEffect, useState } from "react";

import { ClaimEvent } from "@ohgeez/fsushi/typechain-types/contracts/FSushiAirdropsVotingEscrow";
import { useEthers } from "@usedapp/core";
import { BigNumber } from "ethers";

import { CHAIN_ID } from "../constants";
import useFSushiAirdropsVotingEscrow from "../hooks/useFSushiAirdropsVotingEscrow";
import useLink from "../hooks/useLink";
import { formatAmount } from "../utils";
import CheckAmountVotingEscrow from "./CheckAmountVotingEscrow";
import ConnectWalletButton from "./ConnectWalletButton";

const ClaimFSushiVotingEscrow = () => {
    const { account, chainId } = useEthers();
    const [amount, setAmount] = useState(BigNumber.from(0));
    const [event, setEvent] = useState<ClaimEvent>();
    const { claim, loading } = useFSushiAirdropsVotingEscrow();

    useEffect(() => {
        if (!account) {
            setAmount(BigNumber.from(0));
        }
    }, [account]);

    const onClick = async () => {
        if (amount && !amount.isZero()) {
            setEvent(await claim());
        }
    };

    return (
        <>
            {!account || chainId != CHAIN_ID ? (
                <ConnectWalletButton className={"w-full"} />
            ) : loading ? (
                <button className={"w-full"} disabled={true}>
                    Claiming... {formatAmount(amount)}&nbsp;<b>fSUSHI</b>
                </button>
            ) : event ? (
                <button className={"w-full"} onClick={useLink("https://etherscan.io/tx/" + event.transactionHash)}>
                    Claimed {formatAmount(event.args.amount)} <b>fSUSHI</b>
                </button>
            ) : amount && !amount.isZero() ? (
                <button className={"w-full"} onClick={onClick}>
                    Claim {formatAmount(amount)}&nbsp;<b>fSUSHI</b>
                </button>
            ) : amount && amount.isZero() ? (
                <button className={"w-full"} disabled={true}>
                    {"Not claimable"}
                </button>
            ) : (
                <CheckAmountVotingEscrow amount={amount} setAmount={setAmount} />
            )}
        </>
    );
};

export default ClaimFSushiVotingEscrow;
