import { useEthers } from "@usedapp/core";
import { BigNumber, Contract } from "ethers";

import abi from "../abis/ChainlinkPriceFeed.json";

const CONTRACT = "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419";

let CACHE: number;
let lastUpdated: number;

const useEthereumPrice = () => {
    const { library } = useEthers();
    const fetchPrice = async () => {
        // cache for 1 min
        if (!lastUpdated || Date.now() - lastUpdated < 300_000) {
            try {
                const feed = new Contract(CONTRACT, abi, library);
                CACHE = (await feed.callStatic.latestAnswer()).div(BigNumber.from(10).pow(6)).toNumber();
                lastUpdated = Date.now();
            } catch (e) {
                console.error(e);
                return 0;
            }
        }
        return CACHE;
    };

    return { fetchPrice };
};

export default useEthereumPrice;
