import React from "react";

import { useEthers } from "@usedapp/core";
import { BigNumber } from "ethers";

import useFSushiAirdropsVotingEscrow from "../hooks/useFSushiAirdropsVotingEscrow";
import { formatAmount } from "../utils";
import ErrorMessage from "./ErrorMessage";

const CheckAmountVotingEscrow = (props: { amount: BigNumber; setAmount: (a: BigNumber) => void }) => {
    const { library } = useEthers();
    const { getAmountClaimable, error, loading } = useFSushiAirdropsVotingEscrow();

    const checkAmount = async () => {
        if (library) {
            const amount = await getAmountClaimable();
            if (amount) {
                props.setAmount(amount);
            }
        } else {
            alert("⚠️Your wallet doesn't support json-rpc.");
        }
    };

    return (
        <div className={"w-full"}>
            {loading ? (
                <button className={"w-full"} disabled={true}>
                    Checking...
                </button>
            ) : !props.amount.isZero() ? (
                <button className={"w-full"} disabled={true}>
                    Claim {formatAmount(props.amount)} <b>fSUSHI</b> claimable
                </button>
            ) : (
                <button className={"w-full"} onClick={checkAmount}>
                    Check claimable amount
                </button>
            )}
            <ErrorMessage text={error} />
        </div>
    );
};

export default CheckAmountVotingEscrow;
