import { BigNumber } from "ethers";

export const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY;
export const API_SERVER = process.env.NODE_ENV === "development" ? "http://localhost:3001/" : "https://api.fsushi.org/";
export const CHAIN_ID = /*process.env.NODE_ENV == "development" ? 5 : */ 1;
export const AIRDROP_ID_60k = 0;
export const WETH = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
export const SUSHI = "0x6B3595068778DD592e39A122f4f5a5cF09C90fE2";
export const START_WEEK = 2766;
export const WEIGHT_BASE = BigNumber.from(10).pow(18);
