import React, { useEffect, useState } from "react";

import { ClaimEvent } from "@ohgeez/fsushi/typechain-types/contracts/FSushiAirdrops";
import { useEthers } from "@usedapp/core";
import { BigNumber } from "ethers";

import { CHAIN_ID } from "../constants";
import useFSushiAirdrops, { ClaimParams } from "../hooks/useFSushiAirdrops";
import useLink from "../hooks/useLink";
import { formatAmount } from "../utils";
import CheckEligibility from "./CheckEligibility";
import ConnectWalletButton from "./ConnectWalletButton";
import ErrorMessage from "./ErrorMessage";

const ClaimFSushi = () => {
    const { account, chainId } = useEthers();
    const [params, setParams] = useState<ClaimParams>();
    const [event, setEvent] = useState<ClaimEvent>();
    const { queryClaimEvent, claim, error, loading } = useFSushiAirdrops();
    const amount = params?.amount ? BigNumber.from(params.amount) : undefined;

    useEffect(() => {
        if (!account) {
            setParams(undefined);
        }
    }, [account]);

    const onClick = async () => {
        let e = await queryClaimEvent();
        if (e) setEvent(e);
        else if (params) {
            await claim(params);
            e = await queryClaimEvent();
            if (e) setEvent(e);
        }
    };

    return (
        <div className={"w-full"}>
            {!account || chainId != CHAIN_ID ? (
                <ConnectWalletButton className={"w-full"} />
            ) : loading ? (
                <button className={"w-full"} disabled={true}>
                    {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                    Claiming {formatAmount(amount!)} <b>fSUSHI</b>...
                </button>
            ) : event ? (
                <button className={"w-full"} onClick={useLink("https://etherscan.io/tx/" + event.transactionHash)}>
                    Claimed {formatAmount(event.args.amount)} fSUSHI
                </button>
            ) : amount && !amount.isZero() ? (
                <button className={"w-full"} onClick={onClick}>
                    Claim {formatAmount(amount)} fSUSHI
                </button>
            ) : amount && amount.isZero() ? (
                <button className={"w-full"} disabled={true}>
                    You&apos;re not eligible :(
                </button>
            ) : (
                <CheckEligibility params={params} setParams={setParams} />
            )}
            <ErrorMessage text={error} />
        </div>
    );
};

export default ClaimFSushi;
