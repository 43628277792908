import useLocalStorage from "use-local-storage";

const useUpdater = () => {
    const [lastUpdated, setLastUpdated] = useLocalStorage("last-updated", 0);
    const update = () => setLastUpdated(Date.now());

    return { lastUpdated, update };
};

export default useUpdater;
