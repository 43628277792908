import React, { useState } from "react";

import { WithdrawEvent } from "@ohgeez/fsushi/typechain-types/contracts/FSushiBar";
import { useEthers } from "@usedapp/core";
import { BigNumber } from "ethers";
import useAsyncEffect from "use-async-effect";

import useFSushiBar from "../hooks/useFSushiBar";
import useLink from "../hooks/useLink";
import useUpdater from "../hooks/useUpdater";
import { formatAmount } from "../utils";
import ErrorMessage from "./ErrorMessage";

const WithdrawFSushi = () => {
    const { account } = useEthers();
    const { lastUpdated, update } = useUpdater();
    const { getSharesAndAssets, previewWithdraw, withdraw, withdrawing } = useFSushiBar();
    const [preview, setPreview] = useState<{ shares: BigNumber; assets: BigNumber }>();
    const [balance, setBalance] = useState<{ shares: BigNumber; assets: BigNumber }>();
    const [error, setError] = useState("");
    const [event, setEvent] = useState<WithdrawEvent>();

    useAsyncEffect(async () => {
        setPreview(undefined);
        setBalance(undefined);
        if (account) {
            setPreview(await previewWithdraw());
            setBalance(await getSharesAndAssets());
        }
    }, [account, lastUpdated]);

    const onWithdraw = event
        ? useLink("https://etherscan.io/tx/" + event.transactionHash)
        : async () => {
              setError("");
              try {
                  setEvent(await withdraw());
                  update();
              } catch (e) {
                  if (e instanceof Error) setError(e.message);
              }
          };

    return (
        <div className={"mt-5"}>
            <button
                className={"w-full"}
                onClick={onWithdraw}
                disabled={withdrawing || !preview || preview.shares.isZero()}
            >
                {withdrawing
                    ? "Withdrawing..."
                    : event
                    ? formatAmount(event.args.assets) + " fSUSHI Withdrawn"
                    : "Withdraw"}
            </button>
            <div className={"bubble mt-4"}>
                <div className={"caption"}>
                    {preview && !preview.shares.isZero() ? (
                        <span>
                            You&apos;ll burn <b>{formatAmount(preview.shares)} xfSUSHI</b> and receive{" "}
                            <b>{formatAmount(preview.assets)} fSUSHI</b>.
                        </span>
                    ) : (
                        <span>You don&apos;t have any expired xfSUSHI lock.</span>
                    )}
                    {balance && (
                        <span>
                            {" "}
                            You&apos;re holding <b>{formatAmount(balance.shares)} xfSUSHI</b> that can be redeemd to{" "}
                            <b>{formatAmount(balance.assets)} fSUSHI</b> later.
                        </span>
                    )}
                </div>
            </div>
            <ErrorMessage text={error} />
        </div>
    );
};

export default WithdrawFSushi;
