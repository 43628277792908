import { useState } from "react";

import abi from "@ohgeez/fsushi/abis/UniswapV2Pair.json";
import { UniswapV2Pair } from "@ohgeez/fsushi/typechain-types/contracts/mocks/uniswapv2/UniswapV2Pair.sol/UniswapV2Pair";
import { useEthers } from "@usedapp/core";
import { Contract } from "ethers";
import useAsyncEffect from "use-async-effect";

import subgraph from "../data/pools.json";
import useTokens, { Token } from "./useTokens";

export interface Pool {
    id: number;
    address: string;
    token0: Token;
    token1: Token;
    allocPoint: number;
}

let fetching = false;

const useSushiSwapPools = () => {
    const { library } = useEthers();
    const [pools, setPools] = useState<Array<Pool>>([]);
    const [refreshing, setRefreshing] = useState(false);
    const [error, setError] = useState("");
    const { fetchToken } = useTokens();

    const refresh = async () => {
        setPools([]);
        setRefreshing(true);
        const data = subgraph.data.masterChefPools;
        const fetchPool = async (id: number, address: string, allocPoint: number) => {
            const pair = new Contract(address, abi, library) as UniswapV2Pair;
            const fallbackFetchToken = async (name: string) => {
                try {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const address = await pair.callStatic[name]();
                    return await fetchToken(address);
                } catch (e) {
                    return null;
                }
            };
            const [token0, token1] = await Promise.all([fallbackFetchToken("token0"), fallbackFetchToken("token1")]);
            if (token0 && token1) {
                setPools(pools =>
                    [...pools, { id, address, token0, token1, allocPoint } as Pool].sort(
                        (a, b) => b.allocPoint - a.allocPoint
                    )
                );
            }
        };
        try {
            await Promise.all(
                data.map(({ id, lpToken, allocPoint }) => fetchPool(Number(id), lpToken, Number(allocPoint)))
            );
        } catch (e) {
            if (e instanceof Error) {
                console.error(e.message);
                setError(e.message);
            }
        } finally {
            setRefreshing(false);
        }
    };

    useAsyncEffect(() => {
        if (!fetching) {
            fetching = true;
            refresh().finally(() => {
                fetching = false;
            });
        }
    }, []);

    return {
        pools,
        refresh,
        refreshing,
        error,
    };
};

export default useSushiSwapPools;
