import abi from "@ohgeez/fsushi/abis/BaseERC20.json";
import { BaseERC20 } from "@ohgeez/fsushi/typechain-types/contracts/base/BaseERC20";
import { useEthers } from "@usedapp/core";
import { Contract } from "ethers";

const useBaseERC20 = () => {
    const { library } = useEthers();

    const getBalanceOf = async (address: string, account: string) => {
        if (library) {
            const erc20 = new Contract(address, abi, library) as BaseERC20;
            return await erc20.balanceOf(account);
        }
    };

    const getTotalSupply = async (address: string) => {
        if (library) {
            const erc20 = new Contract(address, abi, library) as BaseERC20;
            return await erc20.totalSupply();
        }
    };

    return {
        getBalanceOf,
        getTotalSupply,
    };
};

export default useBaseERC20;
