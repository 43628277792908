import React, { useContext } from "react";

import ReactLoading from "react-loading";

import GlobalContext from "../contexts/GlobalContext";

const Loading = () => {
    const { theme } = useContext(GlobalContext);
    return (
        <div className={"w-full flex justify-center mt-8"}>
            <ReactLoading type={"balls"} color={theme == "lite" ? "#e06b40" : "#FFFFFF"} />
        </div>
    );
};

export default Loading;
