import { useState } from "react";

import abi from "@ohgeez/fsushi/abis/FSushiAirdrops.json";
import goerlie from "@ohgeez/fsushi/deployments/goerli/FSushiAirdrops.json";
import mainnet from "@ohgeez/fsushi/deployments/mainnet/FSushiAirdrops.json";
import { FSushiAirdrops } from "@ohgeez/fsushi/typechain-types/contracts/FSushiAirdrops";
import { useEthers } from "@usedapp/core";
import { Contract } from "ethers";

import { AIRDROP_ID_60k } from "../constants";

const deployment = process.env.NODE_ENV == "development" ? goerlie : mainnet;

export interface ClaimParams {
    account: string;
    amount: string;
    beneficiary: string;
    deadline: number;
    v: number;
    r: string;
    s: string;
}

const useFSushiAirdrops = () => {
    const { account, library } = useEthers();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [claimed, setClaimed] = useState(false);

    const queryClaimEvent = async () => {
        if (library && account) {
            const airdrops = new Contract(deployment.address, abi, library.getSigner()) as FSushiAirdrops;
            const events = await airdrops.queryFilter(airdrops.filters.Claim(AIRDROP_ID_60k, null, account));
            if (events.length > 0) {
                return events[0];
            }
        }
    };

    const claim = async (params: ClaimParams) => {
        if (library) {
            console.log(params);
            try {
                setLoading(true);
                const airdrops = new Contract(deployment.address, abi, library.getSigner()) as FSushiAirdrops;
                const tx = await airdrops.claim(
                    AIRDROP_ID_60k,
                    params.amount,
                    params.beneficiary,
                    params.deadline,
                    params.v,
                    params.r,
                    params.s
                );
                await tx.wait();
                setClaimed(true);
            } catch (e) {
                if (e instanceof Error) setError(e.message);
            } finally {
                setLoading(false);
            }
        }
    };

    return {
        queryClaimEvent,
        claim,
        loading,
        error,
        claimed,
    };
};

export default useFSushiAirdrops;
