import React, { useState } from "react";

import { ClaimRewardsEvent } from "@ohgeez/fsushi/typechain-types/contracts/FSushiBill";
import { useEthers } from "@usedapp/core";
import { BigNumber } from "ethers";
import useAsyncEffect from "use-async-effect";

import useFSushiBill from "../hooks/useFSushiBill";
import useLink from "../hooks/useLink";
import { Pool } from "../hooks/useSushiSwapPools";
import useUpdater from "../hooks/useUpdater";
import { formatAmount } from "../utils";
import ConnectWalletButton from "./ConnectWalletButton";
import ErrorMessage from "./ErrorMessage";

const ClaimRewards = (props: { pool: Pool }) => {
    const { account, library } = useEthers();
    const { lastUpdated, update } = useUpdater();
    const [amount, setAmount] = useState<BigNumber>();
    const { estimateRewards, claimRewards, claiming, error } = useFSushiBill();
    const [event, setEvent] = useState<ClaimRewardsEvent>();

    useAsyncEffect(async () => {
        if (account && library) {
            setAmount(await estimateRewards(props.pool.id));
        }
    }, [account, library, lastUpdated]);

    const onClaim = event
        ? useLink("https://etherscan.io/tx/" + event.transactionHash)
        : async () => {
              setEvent(await claimRewards(props.pool.id));
              update();
          };

    return account ? (
        <div>
            <div>You can receive:</div>
            <div className={"subtitle text-right"}>
                <b>{amount ? formatAmount(amount) : "..."} fSUSHI</b>
            </div>
            <button className={"w-full mt-2"} onClick={onClaim} disabled={claiming || !amount || amount.isZero()}>
                {claiming ? "Claiming..." : event ? formatAmount(event.args.amount) + " fSUSHI Claimed" : "Claim"}
            </button>
            <div className={"bubble mt-4"}>
                <div className={"caption"}>
                    This is based on a rough estimation so in some cases it could be incorrect.
                </div>
            </div>
            <ErrorMessage text={error} />
        </div>
    ) : (
        <ConnectWalletButton />
    );
};

export default ClaimRewards;
