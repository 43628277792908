import { BigNumber, BigNumberish, providers, utils } from "ethers";

export const DAY = 60 * 60 * 24;
export const WEEK = 7 * DAY;

export const formatAmount = (amount: BigNumberish, visibleDecimals = 4, decimals = 18) => {
    const dummy = BigNumber.from(10).pow(decimals - visibleDecimals);
    return utils.formatUnits(BigNumber.from(amount).div(dummy).mul(dummy), decimals);
};

export const formatPercentage = (amount: BigNumberish, total: BigNumberish) => {
    return BigNumber.from(total).isZero()
        ? "0%"
        : formatAmount(BigNumber.from(amount).mul(10000).div(total), 2, 4) + "%";
};

export const formatUSD = (amount: BigNumber, fractions = 2) => {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 2,
    });
    const dummy = BigNumber.from(10).pow(18);
    return formatter.format(parseInt(utils.formatUnits(amount.div(dummy).mul(dummy), 18 + fractions)));
};

export const latest = async (provider: providers.JsonRpcProvider) => {
    const block = await provider.getBlockNumber();
    const { timestamp } = await provider.getBlock(block);
    return timestamp;
};

export const toWeekNumber = (timestamp: number) => Math.floor(timestamp / WEEK);

export const toTimestamp = (weekNumber: number) => Math.floor(weekNumber) * WEEK;

export const addressEquals = (addressA: string, addressB: string) =>
    utils.getAddress(addressA) == utils.getAddress(addressB);
