import React, { useContext, useEffect } from "react";

import FarmCard from "../components/FarmCard";
import Loading from "../components/Loading";
import Menu from "../components/Menu";
import WeekCard from "../components/WeekCard";
import GlobalContext from "../contexts/GlobalContext";

const Farms = () => {
    const context = useContext(GlobalContext);

    return (
        <div>
            <Menu currentPath={"farms"} />
            <div className={"container"}>
                <div className={"pane w-full"}>
                    <div className={"subtitle mb-8"}>fSUSHI Farms</div>
                    <div className={"mt-4 font-normal"}>
                        <p>
                            If you have SushiSwap LP tokens, you can wrap it into fTokens and start earning{" "}
                            <b>fSUSHI</b>. What&apos;s important is, every <b>Thursday 00:00 UTC</b> is the end of a
                            week and at that moment, your yield accumulates for the last week.
                        </p>
                        <p>
                            <i>
                                You can claim every week or wait for weeks and claim cumulative amount whenever you
                                want.
                            </i>
                        </p>
                    </div>
                    <div className={"mt-12 w-full grid md:grid-cols-3 gap-6"}>
                        <WeekCard />
                        {context.pools &&
                            context.pools.length > 0 &&
                            context.pools?.map(pool => {
                                return <FarmCard key={pool.id} pool={pool} />;
                            })}
                        {context.pools?.length == 0 && <Loading />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Farms;
