import React, { createContext, PropsWithChildren, useState } from "react";

import { useEthers } from "@usedapp/core";
import { BigNumber } from "ethers";
import useAsyncEffect from "use-async-effect";

import useSousChef from "../hooks/useSousChef";
import useSushiSwapPools, { Pool } from "../hooks/useSushiSwapPools";

export type Theme = "lite" | "dark";

const GlobalContext = createContext({
    theme: "lite" as Theme,
    setTheme: (theme: Theme) => {
        // Empty
    },
    pools: undefined as Pool[] | undefined,
    weeklyRewards: undefined as BigNumber | undefined,
});

const updateTheme = () => {
    if (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches) {
        localStorage.theme = "dark";
    }
    console.log(localStorage.theme);
    if (localStorage.theme === "dark") {
        document.documentElement.classList.add("dark");
    } else {
        document.documentElement.classList.remove("dark");
    }
};
document.addEventListener("DOMContentLoaded", function () {
    updateTheme();
});

export const GlobalContextProvider = (props: PropsWithChildren<unknown>) => {
    const { library } = useEthers();
    const { pools } = useSushiSwapPools();
    const { getWeeklyRewards } = useSousChef();
    const [weeklyRewards, setWeeklyRewards] = useState<BigNumber>();

    const setTheme = (theme: Theme) => {
        localStorage.theme = theme;
        updateTheme();
    };

    useAsyncEffect(async () => {
        if (library) {
            setWeeklyRewards(await getWeeklyRewards());
        }
    }, [library]);

    return (
        <GlobalContext.Provider value={{ theme: localStorage.theme || "lite", setTheme, pools, weeklyRewards }}>
            {props.children}
        </GlobalContext.Provider>
    );
};

export default GlobalContext;
