import { useState } from "react";

import abi from "@ohgeez/fsushi/abis/FSushiBill.json";
import { ClaimRewardsEvent, FSushiBill } from "@ohgeez/fsushi/typechain-types/contracts/FSushiBill";
import { useEthers } from "@usedapp/core";
import { BigNumber, Contract } from "ethers";

import { START_WEEK, WEIGHT_BASE, WETH } from "../constants";
import { formatUSD, latest, toTimestamp, toWeekNumber } from "../utils";
import useBaseERC20 from "./useBaseERC20";
import useEthereumPrice from "./useEthereumPrice";
import useFarmingLPToken from "./useFarmingLPToken";
import useFSushiKitchen from "./useFSushiKitchen";
import useSousChef from "./useSousChef";

export interface PastRewards {
    week: number;
    amount: BigNumber;
}

const useFSushiBill = () => {
    const { account, library } = useEthers();
    const { getLPToken } = useFarmingLPToken();
    const { fetchPrice } = useEthereumPrice();
    const { getBill, getWeeklyRewards } = useSousChef();
    const { getRelativeWeightAt } = useFSushiKitchen();
    const { getBalanceOf, getTotalSupply } = useBaseERC20();
    const [claiming, setClaiming] = useState(false);
    const [error, setError] = useState("");

    const getTVL = async (pid: number) => {
        if (library) {
            const lpToken = await getLPToken(pid);
            if (lpToken) {
                const token0 = await lpToken.token0();
                const [amount0, amount1] = await lpToken.getReserves();
                const amountWeth = WETH == token0 ? amount0 : amount1;
                const price = await fetchPrice();
                return formatUSD(amountWeth.mul(2).mul(price));
            }
        }
    };

    const getPastRewards = async (pid: number, max = 5) => {
        const rewards = [] as PastRewards[];
        if (library) {
            const bill = await getBill(pid);
            if (bill) {
                const to = toWeekNumber(await latest(library));
                const from = Math.max(to - max, START_WEEK);
                console.log(from, to);
                for (let week = from; week < to; week++) {
                    const weeklyRewards = await getWeeklyRewards(week);
                    const weight = await getRelativeWeightAt(pid, toTimestamp(week + 1));
                    if (weeklyRewards && weight) {
                        rewards.push({ week, amount: weeklyRewards.mul(weight).div(WEIGHT_BASE) });
                    }
                }
            }
        }
        return rewards;
    };

    const estimateRewards = async (pid: number) => {
        if (library && account) {
            const bill = await getBill(pid);
            if (bill) {
                let totalRewards = BigNumber.from(0);
                const contract = new Contract(bill, abi, library.getSigner()) as FSushiBill;
                const to = toWeekNumber(await latest(library));
                let from = (await contract.userLastCheckpoint(account)).toNumber();
                if (from == 0) {
                    from = START_WEEK;
                }
                for (let i = 0; i < 512; i++) {
                    const week = from + i;
                    if (to <= week) break;
                    const weeklyRewards = await getWeeklyRewards(week);
                    const weight = await getRelativeWeightAt(pid, toTimestamp(week + 1));
                    const balance = await getBalanceOf(bill, account);
                    const totalSupply = await getTotalSupply(bill);
                    if (weeklyRewards && !weeklyRewards.isZero() && weight && balance && totalSupply) {
                        const rewards = weeklyRewards.mul(weight).mul(balance).div(totalSupply).div(WEIGHT_BASE);
                        totalRewards = totalRewards.add(rewards);
                    }
                }
                return totalRewards;
            }
        }
    };

    const claimRewards = async (pid: number) => {
        if (library && account) {
            try {
                setClaiming(true);
                const bill = await getBill(pid);
                if (bill) {
                    const contract = new Contract(bill, abi, library.getSigner()) as FSushiBill;
                    const tx = await contract.claimRewards(account);
                    const { events } = await tx.wait();
                    return events?.find(e => e.event == "ClaimRewards") as ClaimRewardsEvent | undefined;
                }
            } catch (e) {
                if (e instanceof Error) setError(e.message);
            } finally {
                setClaiming(false);
            }
        }
    };

    return {
        getTVL,
        getPastRewards,
        estimateRewards,
        claimRewards,
        claiming,
        error,
    };
};

export default useFSushiBill;
