import { useContext, useState } from "react";

import React, { useEthers } from "@usedapp/core";
import useAsyncEffect from "use-async-effect";

import GlobalContext from "../contexts/GlobalContext";
import { FSUSHI } from "../hooks/useFSushi";
import useLink from "../hooks/useLink";
import { formatAmount, latest, toTimestamp, toWeekNumber } from "../utils";
import TokenLogo from "./TokenLogo";

const WeekCard = () => {
    const { library } = useEthers();
    const { weeklyRewards } = useContext(GlobalContext);
    const [weekEnd, setWeekEnd] = useState<number>();

    useAsyncEffect(async () => {
        if (library) {
            setWeekEnd(toTimestamp(toWeekNumber(await latest(library)) + 1));
        }
    }, [library]);

    return (
        <div className={"card"}>
            <div className={"heading flex flex-row items-center"}>
                <TokenLogo address={FSUSHI} />
                <span className={"ml-2"}>OVERVIEW</span>
            </div>
            <div className={"mt-4"}>
                <div>
                    <div>
                        <div className={"note"}>This week&apos;s emissions</div>
                        <div>{weeklyRewards ? formatAmount(weeklyRewards) + " fSUSHI" : "Loading..."}</div>
                    </div>
                    <div className={"mt-2"}>
                        <div className={"note"}>End of this week</div>
                        <div>{weekEnd ? new Date(weekEnd * 1000).toLocaleString() : "Loading..."}</div>
                    </div>
                </div>
            </div>
            <div className={"mt-4"}>
                <button className={"btn-twitter w-full mb-2"} onClick={useLink("https://twitter.com/fsushi_org")}>
                    Follow Twitter
                </button>
            </div>
        </div>
    );
};

export default WeekCard;
