import abi from "@ohgeez/fsushi/abis/BaseERC20.json";
import { BaseERC20 } from "@ohgeez/fsushi/typechain-types/contracts/base/BaseERC20";
import { useEthers } from "@usedapp/core";
import { BigNumberish, Contract, utils } from "ethers";

const useERC2612 = (contract: string, name: string, version: string) => {
    const { account, library, chainId } = useEthers();

    const sign = async (spender: string, value: BigNumberish, deadline: number) => {
        if (library && account) {
            const erc20 = new Contract(contract, abi, library) as BaseERC20;
            const nonce = await erc20.nonces(account);
            return utils.splitSignature(
                await library.getSigner()._signTypedData(
                    {
                        name,
                        version,
                        chainId,
                        verifyingContract: contract,
                    },
                    {
                        Permit: [
                            {
                                name: "owner",
                                type: "address",
                            },
                            {
                                name: "spender",
                                type: "address",
                            },
                            {
                                name: "value",
                                type: "uint256",
                            },
                            {
                                name: "nonce",
                                type: "uint256",
                            },
                            {
                                name: "deadline",
                                type: "uint256",
                            },
                        ],
                    },
                    {
                        owner: account,
                        spender,
                        value,
                        nonce,
                        deadline,
                    }
                )
            );
        }
    };

    return { sign };
};

export default useERC2612;
