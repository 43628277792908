import React from "react";

import ClaimFSushi from "../components/ClaimFSushi";
import ClaimFSushiVotingEscrow from "../components/ClaimFSushiVotingEscrow";
import Menu from "../components/Menu";

const Airdrops = () => {
    return (
        <div>
            <Menu currentPath={"airdrops"} />
            <div className={"container"}>
                <div className={"pane grid md:grid-cols-2 gap-6"}>
                    <div className={"card"}>
                        <div className={"heading"}>60k fSUSHI airdrop for SUSHI holders</div>
                        <div className={"my-4"}>
                            <p>
                                If you were holding SUSHI tokens at Ethereum block 13249100, you&apos;re eligible for
                                claiming fSUSHI.
                            </p>
                            <p>
                                (Block 13249100 was the end of one era of SushiSwap, due to{" "}
                                <a
                                    href={
                                        "https://twitter.com/0xMaki/status/1439037342740729857?cxt=HHwWgsCqidi1vvgnAAAA"
                                    }
                                    target={"_blank"}
                                    rel="noreferrer"
                                >
                                    0xMaki transitioning to the advisor of SushiSwap,
                                </a>{" "}
                                and we consider those who held SUSHI at that time as the OG SushiSwap community.)
                            </p>
                        </div>
                        <ClaimFSushi />
                    </div>
                    <div className={"card"}>
                        <div className={"heading"}>10k fSUSHI airdrop for veLEVX holders</div>
                        <div className={"my-4"}>
                            <p>
                                veLEVX holders can receive fSUSHI every week but according to the halving schedule. The
                                snapshot is taken every <u>Thursday 00:00 UTC</u> so if you increase your veLEVX
                                balances next week, you could get higher shares of the weekly emissions.
                            </p>
                            <p>
                                <i>
                                    You can claim every week or wait for weeks and claim cumulative amount whenever you
                                    want.
                                </i>
                            </p>
                        </div>
                        <ClaimFSushiVotingEscrow />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Airdrops;
