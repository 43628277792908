import React, { useEffect } from "react";

import { Mainnet, DAppProvider, Config } from "@usedapp/core";
import { providers } from "ethers";
import { HashRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";

import Footer from "./components/Footer";
import Header from "./components/Header";
import { ALCHEMY_API_KEY } from "./constants";
import { GlobalContextProvider } from "./contexts/GlobalContext";
import Airdrops from "./pages/Airdrops";
import Farming from "./pages/Farming";
import Farms from "./pages/Farms";
import Staking from "./pages/Staking";

const config: Config = {
    autoConnect: false,
    readOnlyChainId: Mainnet.chainId,
    readOnlyUrls: {
        [Mainnet.chainId]: new providers.AlchemyProvider(1, ALCHEMY_API_KEY),
    },
};

function App() {
    return (
        <DAppProvider config={config}>
            <GlobalContextProvider>
                <HashRouter basename={process.env.PUBLIC_URL}>
                    <div>
                        <ScrollToTop />
                        <Header />
                        <Routes>
                            <Route path="/" element={<Farms />} />
                            <Route path="/staking" element={<Staking />} />
                            <Route path="/airdrops" element={<Airdrops />} />
                            <Route path="/farms/:pid" element={<Farming />} />
                            <Route path="/farms" element={<Navigate to={"/"} replace={true} />} />
                            <Route path="*" element={<Navigate to={"/"} replace={true} />} />
                        </Routes>
                        <Footer />
                    </div>
                </HashRouter>
            </GlobalContextProvider>
        </DAppProvider>
    );
}

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export default App;
