import { useState } from "react";

import abi from "@ohgeez/fsushi/abis/FSushi.json";
import deployment from "@ohgeez/fsushi/deployments/mainnet/FSushi.json";
import { ApprovalEvent, FSushi } from "@ohgeez/fsushi/typechain-types/contracts/FSushi";
import { useEthers } from "@usedapp/core";
import { BigNumberish, Contract } from "ethers";

export const FSUSHI = deployment.address;

const useFSushi = () => {
    const { account, library } = useEthers();
    const [approving, setApproving] = useState(false);
    const [error, setError] = useState("");

    const approve = async (spender: string, amount: BigNumberish) => {
        if (library && account) {
            try {
                setApproving(true);
                const bar = new Contract(deployment.address, abi, library.getSigner()) as FSushi;
                const tx = await bar.approve(spender, amount);
                const { events } = await tx.wait();
                return events?.find(e => e.event == "Approval") as ApprovalEvent | undefined;
            } catch (e) {
                if (e instanceof Error) setError(e.message);
            } finally {
                setApproving(false);
            }
        }
    };

    return {
        approve,
        approving,
        error,
    };
};

export default useFSushi;
