import abi from "@ohgeez/fsushi/abis/SousChef.json";
import deployment from "@ohgeez/fsushi/deployments/mainnet/SousChef.json";
import { SousChef } from "@ohgeez/fsushi/typechain-types/contracts/SousChef";
import { useEthers } from "@usedapp/core";
import { Contract } from "ethers";

import { latest, toWeekNumber } from "../utils";

const useSousChef = () => {
    const { library } = useEthers();

    const getWeeklyRewards = async (week?: number) => {
        if (library) {
            if (!week) week = toWeekNumber(await latest(library));
            const chef = new Contract(deployment.address, abi, library) as SousChef;
            return await chef.weeklyRewards(week);
        }
    };

    const getBill = async (pid: number) => {
        if (library) {
            const chef = new Contract(deployment.address, abi, library) as SousChef;
            return await chef.getBill(pid);
        }
    };

    return {
        getWeeklyRewards,
        getBill,
    };
};

export default useSousChef;
