import { useState } from "react";

import abi from "@ohgeez/fsushi/abis/FSushiCookV0.json";
import deployment from "@ohgeez/fsushi/deployments/mainnet/FSushiCookV0.json";
import { DepositEvent } from "@ohgeez/fsushi/typechain-types/contracts/FSushiBill";
import { FSushiCookV0 } from "@ohgeez/fsushi/typechain-types/contracts/FSushiCookV0.sol/FSushiCookV0";
import { useEthers } from "@usedapp/core";
import { BigNumberish, Contract } from "ethers";

export const COOK = deployment.address;

const useFSushiCook = () => {
    const { library, account } = useEthers();
    const [cooking, setCooking] = useState(false);
    const [error, setError] = useState("");

    const cook = async (
        pid: number,
        amount: BigNumberish,
        path0: string[],
        path1: string[],
        amountMin: BigNumberish,
        duration: number,
        deadline: number,
        v: number,
        r: string,
        s: string
    ) => {
        if (library && account) {
            try {
                setCooking(false);
                const cook = new Contract(deployment.address, abi, library.getSigner()) as FSushiCookV0;
                const tx = await cook.cook(pid, amount, path0, path1, amountMin, account, duration, deadline, v, r, s);
                const { events } = await tx.wait();
                return events?.find(e => e.event == "Deposit") as DepositEvent | undefined;
            } catch (e) {
                if (e instanceof Error) setError(e.message);
            } finally {
                setCooking(false);
            }
        }
    };

    return {
        cook,
        cooking,
        error,
    };
};

export default useFSushiCook;
