import React from "react";

import { utils } from "ethers";
import ReactImageFallback from "react-image-fallback";

import { FSUSHI } from "../hooks/useFSushi";

const TokenLogo = (props: { address?: string; className?: string }) => {
    const src =
        props.address === FSUSHI
            ? "/logo512.png"
            : props.address
            ? `https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/network/ethereum/${utils.getAddress(
                  props.address
              )}.jpg`
            : "/img/token-fallback.png";
    return (
        <div className={props.className}>
            <ReactImageFallback
                className={"token-logo border border-0.5 border-gray-500"}
                src={src}
                fallbackImage={"/img/token-fallback.png"}
                initialImage={"/img/token-fallback.png"}
                alt={props.address}
            />
        </div>
    );
};

export default TokenLogo;
