import React, { useState } from "react";

import { useEthers, useTokenBalance } from "@usedapp/core";
import { BigNumber } from "ethers";
import useAsyncEffect from "use-async-effect";

import useBaseERC20 from "../hooks/useBaseERC20";
import useSousChef from "../hooks/useSousChef";
import { Pool } from "../hooks/useSushiSwapPools";
import { formatAmount, formatPercentage } from "../utils";

const WithdrawLP = (props: { pool: Pool }) => {
    const { account } = useEthers();
    const [bill, setBill] = useState<string>();
    const balance = useTokenBalance(bill, account);
    const { getBill } = useSousChef();
    const { getTotalSupply } = useBaseERC20();
    const [totalSupply, setTotalSupply] = useState<BigNumber>();

    useAsyncEffect(async () => {
        const bill = await getBill(props.pool.id);
        setBill(bill);
        if (bill) {
            setTotalSupply(await getTotalSupply(bill));
        }
    }, [props.pool.id]);

    return (
        <div className={"mt-5"}>
            <div className={"pr-2 md:pr-0 mt-2 md:mt-0 w-full note mb-2 text-right"}>
                Your balance: {balance ? formatAmount(balance, 8) : "0"} <b>xffLP</b> (
                {balance && totalSupply ? formatPercentage(balance.mul(100), totalSupply) : "0%"})
            </div>
            <button className={"w-full"} disabled={true}>
                Withdraw
            </button>
            <div className={"bubble mt-4"}>
                <div className={"caption"}>
                    <span>You don&apos;t have any expired LP lock.</span>
                </div>
            </div>
        </div>
    );
};

export default WithdrawLP;
