import React from "react";

const Footer = () => {
    return (
        <div className={"container flex flex-col items-center my-12"}>
            <div>
                Built & Operated by{" "}
                <a href={"https://twitter.com/levxdaohgeez"} target={"_blank"} rel="noreferrer">
                    <b>OhGeez DAO 😵‍💫</b>
                </a>
            </div>
            <div className={"mt-2 text-sm"}>
                <a href={"https://twitter.com/fsushi_org"} target={"_blank"} rel="noreferrer">
                    Twitter
                </a>{" "}
                |{" "}
                <a href={"https://discord.gg/ohgeez"} target={"_blank"} rel="noreferrer">
                    Discord
                </a>{" "}
                |{" "}
                <a href={"https://github.com/ohgeez-dao"} target={"_blank"} rel="noreferrer">
                    GitHub
                </a>
            </div>
        </div>
    );
};

export default Footer;
