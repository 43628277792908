import React, { useContext } from "react";

import { useParams } from "react-router-dom";

import ClaimRewards from "../components/ClaimRewards";
import DepositLP from "../components/DepositLP";
import HistoryOfRewards from "../components/HistoryOfRewards";
import Loading from "../components/Loading";
import Menu from "../components/Menu";
import TokenLogo from "../components/TokenLogo";
import WithdrawLP from "../components/WithdrawLP";
import GlobalContext from "../contexts/GlobalContext";

const Farming = () => {
    const { pid } = useParams();
    const context = useContext(GlobalContext);
    const pool = context.pools?.find(pool => pool.id == Number(pid));
    const symbol = pool ? pool.token0.symbol + "-" + pool.token1.symbol : "";

    return (
        <div>
            <Menu currentPath={"farms"} />
            <div className="container">
                <div className={"pane"}>
                    <div className={"flex flex-row"}>
                        <TokenLogo address={pool?.token0.address} />
                        <TokenLogo address={pool?.token1.address} className={"-ml-2"} />
                        <div className={"subtitle mb-8 ml-2"}>{symbol}</div>
                    </div>
                    <div className={"grid md:grid-cols-2 gap-6"}>
                        {pool ? (
                            <>
                                <div className={"card"}>
                                    <div className={"heading"}>Deposit</div>
                                    <div className={"my-4"}>
                                        <p>
                                            You can deposit SushiSwap <b>{symbol}</b> LP tokens to earn fSUSHI.
                                        </p>
                                    </div>
                                    <DepositLP pool={pool} />
                                </div>
                                <div className={"card"}>
                                    <div className={"heading"}>Withdraw</div>
                                    <div className={"my-4"}>
                                        <p>
                                            You can withdraw expired <b>{symbol}</b> LP locks.
                                        </p>
                                        <WithdrawLP pool={pool} />
                                    </div>
                                </div>
                                <div className={"card"}>
                                    <div className={"heading"}>History of Rewards</div>
                                    <div className={"my-4"}>
                                        <p>
                                            It shows the last 5 week&apos;s <b>fSUSHI</b> rewards of this pool.
                                        </p>
                                    </div>
                                    <HistoryOfRewards pool={pool} />
                                </div>
                                <div className={"card"}>
                                    <div className={"heading"}>Claim Rewards</div>
                                    <div className={"my-4"}>
                                        <p>
                                            You can claim <b>fSUSHI</b> rewards accumulated until the last week&apos;s
                                            end.
                                        </p>
                                    </div>
                                    <ClaimRewards pool={pool} />
                                </div>
                            </>
                        ) : (
                            <>
                                <Loading />
                                <Loading />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Farming;
