import abi from "@ohgeez/fsushi/abis/FarmingLPToken.json";
import abiFactory from "@ohgeez/fsushi/abis/FarmingLPTokenFactory.json";
import abiPair from "@ohgeez/fsushi/abis/UniswapV2Pair.json";
import deployment from "@ohgeez/fsushi/deployments/mainnet/FarmingLPTokenFactory.json";
import { FarmingLPToken } from "@ohgeez/fsushi/typechain-types/contracts/FarmingLPToken";
import { FarmingLPTokenFactory } from "@ohgeez/fsushi/typechain-types/contracts/FarmingLPTokenFactory";
import { UniswapV2Pair } from "@ohgeez/fsushi/typechain-types/contracts/mocks/uniswapv2/UniswapV2Pair.sol/UniswapV2Pair";
import { useEthers } from "@usedapp/core";
import { Contract } from "ethers";

const useFarmingLPToken = () => {
    const { library } = useEthers();

    const getLPToken = async (pid: number) => {
        if (library) {
            const factory = new Contract(deployment.address, abiFactory, library) as FarmingLPTokenFactory;
            const flpToken = new Contract(await factory.getFarmingLPToken(pid), abi, library) as FarmingLPToken;
            return new Contract(await flpToken.lpToken(), abiPair, library) as UniswapV2Pair;
        }
    };

    return {
        getLPToken,
    };
};

export default useFarmingLPToken;
