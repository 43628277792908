import React from "react";

import { Link } from "react-router-dom";

const Menu = (props: { currentPath: string }) => {
    return (
        <div className="container mb-8 flex flex-col items-center">
            <div className={"pt-3 flex flex-row justify-center items-center w-full text-lg"}>
                <MenuItem
                    title={"Farms"}
                    path={"farms"}
                    selected={!props.currentPath || props.currentPath == "farms"}
                />
                <MenuItem title={"Staking"} path={"staking"} selected={props.currentPath == "staking"} />
                <MenuItem title={"Airdrops"} path={"airdrops"} selected={props.currentPath == "airdrops"} />
            </div>
        </div>
    );
};

const MenuItem = (props: { title: string; path: string; selected: boolean }) => {
    return (
        <Link
            to={"/" + props.path}
            className={
                props.selected
                    ? "p-3 rounded text-menu-lite dark:text-menu-dark font-medium"
                    : "p-3 text-gray-900 font-medium"
            }
        >
            {props.title}
        </Link>
    );
};

export default Menu;
