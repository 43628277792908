import { useContext, useState } from "react";

import React, { useEthers } from "@usedapp/core";
import { BigNumber, constants } from "ethers";
import useAsyncEffect from "use-async-effect";

import GlobalContext from "../contexts/GlobalContext";
import useFSushiBill from "../hooks/useFSushiBill";
import useFSushiKitchen from "../hooks/useFSushiKitchen";
import { Pool } from "../hooks/useSushiSwapPools";
import { formatAmount } from "../utils";
import ConnectWalletButton from "./ConnectWalletButton";
import TokenLogo from "./TokenLogo";

const FarmCard = (props: { pool: Pool }) => {
    const { account } = useEthers();
    const { weeklyRewards } = useContext(GlobalContext);
    const [relativeWeight, setRelativeWeight] = useState<BigNumber>();
    const { getTVL } = useFSushiBill();
    const { getWeightPoints, getRelativeWeight } = useFSushiKitchen();
    const [tvl, setTVL] = useState<string>();
    const [weight, setWeight] = useState<BigNumber>();

    useAsyncEffect(async () => {
        setTVL(await getTVL(props.pool.id));
        setWeight(await getWeightPoints(props.pool.id));
        setRelativeWeight(await getRelativeWeight(props.pool.id));
    }, []);

    const onClick = () => (window.location.href = "/#/farms/" + props.pool.id);

    return (
        <div className={"card"}>
            <span className={"heading flex flex-row items-center"}>
                <TokenLogo address={props.pool.token0.address} />
                <TokenLogo address={props.pool.token1.address} className={"-ml-2"} />
                <span className={"ml-2"}>
                    {props.pool.token0.symbol}-{props.pool.token1.symbol}
                </span>
            </span>
            <div className={"mt-4"}>
                <div className={"note"}>This week&apos;s emissions</div>
                <div>
                    {weeklyRewards && relativeWeight
                        ? formatAmount(relativeWeight.mul(weeklyRewards).div(constants.WeiPerEther)) + " fSUSHI"
                        : "Loading..."}
                </div>
                <div className={"flex flex-row justify-between mt-2"}>
                    <div className={"flex-1"}>
                        <div className={"note"}>Current TVL</div>
                        <div>{tvl || "Loading..."}</div>
                    </div>
                    <div className={"flex-1 text-right"}>
                        <div className={"note"}>Multiplier</div>
                        <div>{weight ? formatAmount(weight, 2, 20) + "x" : "Loading..."}</div>
                    </div>
                </div>
            </div>
            <div className={"mt-4"}>
                {account ? (
                    <button className={"w-full mb-2"} disabled={!account} onClick={onClick}>
                        Select
                    </button>
                ) : (
                    <ConnectWalletButton />
                )}
            </div>
        </div>
    );
};

export default FarmCard;
