import abi from "@ohgeez/fsushi/abis/BaseERC20.json";
import { IERC20Metadata } from "@ohgeez/fsushi/typechain-types/@openzeppelin/contracts/token/ERC20/extensions/IERC20Metadata";
import { useEthers } from "@usedapp/core";
import { Contract } from "ethers";

export interface Token {
    address: string;
    name: string;
    symbol: string;
    decimals: number;
}

const cache: Record<string, Token> = {
    "0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2": {
        address: "0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2",
        name: "Maker",
        symbol: "MKR",
        decimals: 18,
    },
};

const useTokens = () => {
    const { library } = useEthers();

    const fetchToken = async (address: string) => {
        if (library) {
            if (cache[address]) return cache[address];
            const contract = new Contract(address, abi, library) as IERC20Metadata;
            const fallbackCall = async (field: string, defaultValue: unknown) => {
                try {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    return await contract.callStatic[field]();
                } catch (e) {
                    return defaultValue;
                }
            };
            const values = await Promise.all([
                fallbackCall("name", ""),
                fallbackCall("symbol", ""),
                fallbackCall("decimals", 18),
            ]);
            const token = {
                address,
                name: values[0] as string,
                symbol: values[1] as string,
                decimals: values[2] as number,
            } as Token;
            cache[address] = token;
            return token;
        }
        throw new Error("No library");
    };

    return { fetchToken };
};

export default useTokens;
