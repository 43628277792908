import React, { ChangeEvent, useEffect, useState } from "react";

import { DepositEvent } from "@ohgeez/fsushi/typechain-types/contracts/FSushiBill";
import { useEthers, useTokenBalance } from "@usedapp/core";
import { BigNumber } from "ethers";

import useERC2612 from "../hooks/useERC2612";
import useFSushiCook, { COOK } from "../hooks/useFSushiCook";
import useLink from "../hooks/useLink";
import useSushiSwap from "../hooks/useSushiSwap";
import { Pool } from "../hooks/useSushiSwapPools";
import useUpdater from "../hooks/useUpdater";
import { formatAmount, WEEK } from "../utils";
import ErrorMessage from "./ErrorMessage";
import TokenAmountInput from "./TokenAmountInput";

const MAX_WEEKS = 104;

const DepositLP = (props: { pool: Pool }) => {
    const { account } = useEthers();
    const { lastUpdated, update } = useUpdater();
    const { cook, cooking, error: cookError } = useFSushiCook();
    const { findPathToSushi, getAmountsOut } = useSushiSwap();
    const balance = useTokenBalance(props.pool.address, account);
    const [weeks, setWeeks] = useState("");
    const [error, setError] = useState("");
    const [event, setEvent] = useState<DepositEvent>();
    const { sign } = useERC2612(props.pool.address, "SushiSwap LP Token", "1");
    const noBalance = balance && balance.isZero();
    const symbol = props.pool ? props.pool.token0.symbol + "-" + props.pool.token1.symbol : "";

    useEffect(() => {
        setWeeks("");
    }, [account, lastUpdated]);

    const onChangeWeek = (event: ChangeEvent<HTMLInputElement>) => {
        setError("");
        try {
            const weeks = parseInt(event.target.value.trim());
            if (weeks < 1 || weeks > MAX_WEEKS) {
                setError("Invalid number of weeks");
            }
            setWeeks(event.target.value.trim());
        } catch (e) {
            if (e instanceof Error) setError(e.message);
        }
    };

    const onCook = noBalance
        ? useLink("https://app.sushi.com/farm/add?chainId=1")
        : event
        ? useLink("https://etherscan.io/tx/" + event.transactionHash)
        : async (amount: BigNumber) => {
              setError("");
              try {
                  const deadline = Math.floor(Date.now() / 1000) + 60 * 10; // 10 mins from now
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  const signature = await sign(COOK, amount, deadline);
                  if (signature) {
                      const path0 = findPathToSushi(props.pool.token0.address);
                      const path1 = findPathToSushi(props.pool.token1.address);
                      const amountSushi0 = await getAmountsOut(props.pool.address, amount.div(2), path0);
                      const amountSushi1 = await getAmountsOut(props.pool.address, amount.div(2), path1);
                      setEvent(
                          await cook(
                              props.pool.id,
                              amount,
                              path0,
                              path1,
                              amountSushi0.add(amountSushi1).mul(99).div(100), // 1% slippage tolerance
                              parseInt(weeks.trim()) * WEEK,
                              deadline,
                              signature.v,
                              signature.r,
                              signature.s
                          )
                      );
                      update();
                  }
              } catch (e) {
                  if (e instanceof Error) setError(e.message);
              }
          };

    return (
        <div>
            <div className={"md:flex flex-row items-end mb-2"}>
                <div className={"relative w-full md:w-28 mr-2 mb-2"}>
                    <input
                        type="number"
                        max={MAX_WEEKS}
                        value={weeks}
                        disabled={!account}
                        onChange={onChangeWeek}
                        placeholder="0"
                        className={"w-full"}
                    />
                    <div className={"absolute right-4 top-2.5 pl-1"}>
                        {account ? <div className={"quote bg-white"}>Weeks</div> : <div className={"quote"}>Weeks</div>}
                    </div>
                </div>
                <TokenAmountInput
                    token={props.pool.address}
                    symbol={"SLP"}
                    disabled={!weeks || !!error || cooking}
                    className={"md:flex-4"}
                    title={
                        noBalance
                            ? "Get SLP"
                            : cooking
                            ? "Depositing..."
                            : event
                            ? formatAmount(event.args.amount) + " " + symbol + "Deposit"
                            : "Deposit"
                    }
                    onSubmit={onCook}
                />
            </div>
            <div className={"bubble"}>
                <div className={"caption"}>
                    <span>
                        Input week from <b>1</b> to <b>{MAX_WEEKS}</b> and amount you want to stake.
                    </span>
                </div>
            </div>
            <ErrorMessage text={error || cookError} />
        </div>
    );
};

export default DepositLP;
