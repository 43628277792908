import abiPair from "@ohgeez/fsushi/abis/UniswapV2Pair.json";
import abiRouter from "@ohgeez/fsushi/abis/UniswapV2Router02.json";
import { UniswapV2Pair } from "@ohgeez/fsushi/typechain-types/contracts/mocks/uniswapv2/UniswapV2Pair.sol/UniswapV2Pair";
import { UniswapV2Router02 } from "@ohgeez/fsushi/typechain-types/contracts/mocks/uniswapv2/UniswapV2Router02";
import { useEthers } from "@usedapp/core";
import { BigNumber, Contract } from "ethers";

import { SUSHI, WETH } from "../constants";
import { addressEquals } from "../utils";

const ROUTER = "0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F";

const useSushiSwap = () => {
    const { library } = useEthers();

    const getAmountsOut = async (lpToken: string, amountLP: BigNumber, path: string[]) => {
        const pair = new Contract(lpToken, abiPair, library) as UniswapV2Pair;
        const [reserve0, reserve1] = await pair.getReserves();
        const reserve = path[0] == (await pair.token0()) ? reserve0 : reserve1;
        const amountIn = amountLP.mul(reserve).div(await pair.totalSupply());

        if (path.length == 1) return BigNumber.from(amountIn);

        const router = new Contract(ROUTER, abiRouter, library) as UniswapV2Router02;
        const amounts = await router.getAmountsOut(amountIn, path);
        return amounts[amounts.length - 1];
    };

    const findPathToSushi = (token: string) => {
        if (addressEquals(token, SUSHI)) {
            return [SUSHI];
        }
        if (addressEquals(token, WETH)) {
            return [WETH, SUSHI];
        }
        return [token, WETH, SUSHI];
    };

    return {
        getAmountsOut,
        findPathToSushi,
    };
};

export default useSushiSwap;
