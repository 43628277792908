import React, { useContext } from "react";

import { shortenAddress, useEthers, useLookupAddress } from "@usedapp/core";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { AiFillCloseCircle } from "react-icons/ai";
import Web3Modal from "web3modal";

import { ALCHEMY_API_KEY, CHAIN_ID } from "../constants";
import GlobalContext from "../contexts/GlobalContext";

const ConnectWalletButton = (props: { className?: string; onError?: (e: string) => void }) => {
    const { isLoading, chainId, account, activate, deactivate } = useEthers();
    const { theme } = useContext(GlobalContext);
    const ens = useLookupAddress();

    const onClick = async () => {
        const providerOptions = {
            injected: {
                display: {
                    name: "Metamask",
                    description: "Connect with the provider in your browser",
                },
                package: null,
            },
            walletconnect: {
                package: WalletConnectProvider,
                options: {
                    rpc: {
                        1: ALCHEMY_API_KEY,
                    },
                },
            },
        };

        const web3Modal = new Web3Modal({
            theme: theme == "dark" ? "dark" : "light",
            providerOptions,
        });
        try {
            const provider = await web3Modal.connect();
            await activate(provider);
        } catch (error) {
            const message = (error as Error).message;
            if (message && !message.startsWith("User")) props.onError?.(message);
        }
    };
    return (
        <div className={props.className}>
            {!account ? (
                <button className={"w-full"} onClick={onClick}>
                    {isLoading ? "Connecting..." : "Connect Wallet"}
                </button>
            ) : chainId !== CHAIN_ID ? (
                <ChangeNetwork />
            ) : (
                <button className={"btn-outline w-full"} onClick={deactivate}>
                    Connected as {ens || shortenAddress(account)} <AiFillCloseCircle className={"ml-1"} />
                </button>
            )}
        </div>
    );
};

const ChangeNetwork = () => {
    const { switchNetwork } = useEthers();
    return (
        <button className={"btn btn-outline w-full"} onClick={() => switchNetwork(CHAIN_ID)}>
            Change Network
        </button>
    );
};

export default ConnectWalletButton;
