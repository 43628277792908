import { useState } from "react";

import abi from "@ohgeez/fsushi/abis/FSushiBar.json";
import deployment from "@ohgeez/fsushi/deployments/mainnet/FSushiBar.json";
import { DepositEvent, FSushiBar, WithdrawEvent } from "@ohgeez/fsushi/typechain-types/contracts/FSushiBar";
import { useEthers } from "@usedapp/core";
import { BigNumber, Contract } from "ethers";

export const XFSUSHI = deployment.address;

const useFSushiBar = () => {
    const { account, library } = useEthers();
    const [depositing, setDepositing] = useState(false);
    const [withdrawing, setWithdrawing] = useState(false);
    const [error, setError] = useState("");

    const getSharesAndAssets = async () => {
        if (library && account) {
            const bar = new Contract(deployment.address, abi, library) as FSushiBar;
            const shares = await bar.balanceOf(account);
            const totalSupply = await bar.totalSupply();
            const assets = totalSupply.isZero()
                ? BigNumber.from(0)
                : (await bar.totalAssets()).mul(shares).div(totalSupply);
            return { shares, assets };
        }
    };

    const previewDeposit = async (amount: BigNumber, weeks: number) => {
        if (library && account) {
            const bar = new Contract(deployment.address, abi, library) as FSushiBar;
            const shares = await bar.previewDeposit(amount, weeks);
            const totalSupply = await bar.totalSupply();
            return { shares, totalSupply };
        }
    };

    const previewWithdraw = async () => {
        if (library && account) {
            const bar = new Contract(deployment.address, abi, library) as FSushiBar;
            return await bar.previewWithdraw(account);
        }
    };

    const depositSigned = async (
        amount: BigNumber,
        weeks: number,
        deadline: number,
        v: number,
        r: string,
        s: string
    ) => {
        if (library && account) {
            try {
                setDepositing(true);
                const bar = new Contract(deployment.address, abi, library.getSigner()) as FSushiBar;
                const tx = await bar.depositSigned(amount, weeks, account, deadline, v, r, s);
                const { events } = await tx.wait();
                return events?.find(e => e.event == "Deposit") as DepositEvent | undefined;
            } catch (e) {
                if (e instanceof Error) setError(e.message);
            } finally {
                setDepositing(false);
            }
        }
    };

    const withdraw = async () => {
        if (library && account) {
            try {
                setWithdrawing(true);
                const bar = new Contract(deployment.address, abi, library.getSigner()) as FSushiBar;
                const tx = await bar.withdraw(account);
                const { events } = await tx.wait();
                return events?.find(e => e.event == "Withdraw") as WithdrawEvent | undefined;
            } catch (e) {
                if (e instanceof Error) setError(e.message);
            } finally {
                setWithdrawing(false);
            }
        }
    };

    return {
        getSharesAndAssets,
        previewDeposit,
        depositSigned,
        depositing,
        previewWithdraw,
        withdraw,
        withdrawing,
        error,
    };
};

export default useFSushiBar;
