import React, { useContext } from "react";

import GlobalContext from "../contexts/GlobalContext";
import ConnectWalletButton from "./ConnectWalletButton";

const Header = () => {
    const { setTheme } = useContext(GlobalContext);
    return (
        <div className={"container relative"}>
            <div
                className={
                    "mt-4 flex justify-center md:absolute md:top-0 md:left-0 md:mx-20 text-black dark:text-gray-300"
                }
            >
                Themes:
                <a className={"mx-1 font-bold dark:font-normal"} href={"javascript:"} onClick={() => setTheme("lite")}>
                    @NomiChef
                </a>
                |
                <a className={"mx-1 font-normal dark:font-bold"} href={"javascript:"} onClick={() => setTheme("dark")}>
                    @0xMaki
                </a>
            </div>
            <a href={"/#"} className={"mt-8 flex flex-col items-center"}>
                <img src={"/img/header.png"} width={168} height={105} alt={"logo"} />{" "}
                <span className={"font-baloo-bhai-2 title mt-1 text-secondary-lite dark:text-secondary-dark"}>
                    fSUSHI
                </span>
            </a>
            <div className={"mt-4 flex justify-center md:absolute md:top-0 md:right-0 md:mx-20"}>
                <ConnectWalletButton />
            </div>
        </div>
    );
};

export default Header;
