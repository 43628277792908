import React, { useEffect, useState } from "react";

import { useEthers, useTokenBalance } from "@usedapp/core";
import { BigNumber, utils } from "ethers";

import { CHAIN_ID } from "../constants";
import { FSUSHI } from "../hooks/useFSushi";
import useUpdater from "../hooks/useUpdater";
import { formatAmount } from "../utils";
import ConnectWalletButton from "./ConnectWalletButton";
import ErrorMessage from "./ErrorMessage";

const TokenAmountInput = (props: {
    title: string;
    onSubmit: (amount: BigNumber) => void | Promise<void>;
    onAmountChange?: (amount: BigNumber) => void;
    disabled?: boolean;
    token?: string;
    symbol?: string;
    className?: string;
}) => {
    const { account, chainId } = useEthers();
    const { lastUpdated } = useUpdater();
    const [amount, setAmount] = useState("");
    const [error, setError] = useState("");
    const balance = useTokenBalance(props.token || FSUSHI, account);

    const onMax = () => {
        if (balance) {
            setAmount(formatAmount(balance, 18));
            props.onAmountChange?.(balance);
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAmount(e.target.value.trim());
        props.onAmountChange?.(utils.parseEther(e.target.value));
    };

    const onClick = async () => {
        let value;
        try {
            value = utils.parseEther(amount.trim());
        } catch (e) {
            value = BigNumber.from(0);
        }
        await props.onSubmit(value);
    };

    useEffect(() => {
        setAmount("");
    }, [lastUpdated]);

    useEffect(() => {
        setError("");
        try {
            if (amount.trim().length > 0) {
                const value = utils.parseEther(amount.trim());
                if (value.gt(balance || 0)) {
                    setError("Insufficient balance");
                }
            }
        } catch {
            setError("Invalid amount");
        }
    }, [amount, balance]);

    return (
        <div className={props.className}>
            <div className={"pr-2 md:pr-0 mt-2 md:mt-0 w-full note mb-2 text-right"}>
                Your balance: {balance ? formatAmount(balance, 8) : "0"} <b>{props.symbol || "fSUSHI"}</b>{" "}
                <a
                    href={"javascript:"}
                    className={"text-secondary-lite dark:text-secondary-dark font-normal"}
                    onClick={onMax}
                >
                    (max)
                </a>
            </div>
            <div className={"md:flex flex-row"}>
                <div className={"relative flex-1 mr-2"}>
                    <input
                        type="text"
                        value={amount}
                        disabled={!account}
                        onChange={onChange}
                        placeholder="0.0000"
                        className={"w-full"}
                    />
                    <div className={"absolute right-4 top-2.5 pl-2"}>
                        {account ? (
                            <div className={"quote bg-white"}>{props.symbol || "fSUSHI"}</div>
                        ) : (
                            <div className={"quote"}>{props.symbol || "fSUSHI"}</div>
                        )}
                    </div>
                </div>
                {!account || chainId !== CHAIN_ID ? (
                    <ConnectWalletButton className={"w-full mt-4 md:w-auto md:mt-0"} />
                ) : (
                    <button
                        className={"w-full mt-4 md:w-auto md:mt-0"}
                        onClick={onClick}
                        disabled={!account || props.disabled || !!error}
                    >
                        {props.title}
                    </button>
                )}
            </div>
            <ErrorMessage text={error} />
        </div>
    );
};

export default TokenAmountInput;
