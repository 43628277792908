import { useState } from "react";

import abi from "@ohgeez/fsushi/abis/FSushiAirdropsVotingEscrow.json";
import deployment from "@ohgeez/fsushi/deployments/mainnet/FSushiAirdropsVotingEscrow.json";
import {
    ClaimEvent,
    FSushiAirdropsVotingEscrow,
} from "@ohgeez/fsushi/typechain-types/contracts/FSushiAirdropsVotingEscrow";
import { useEthers } from "@usedapp/core";
import { BigNumber, Contract } from "ethers";

const useFSushiAirdropsVotingEscrow = () => {
    const { account, library } = useEthers();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [claimed, setClaimed] = useState(false);

    const queryTotalAmountClaimed = async () => {
        if (library && account) {
            const airdrops = new Contract(deployment.address, abi, library.getSigner()) as FSushiAirdropsVotingEscrow;
            const events = await airdrops.queryFilter(airdrops.filters.Claim(account));
            return events
                .map(event => event.args.amount)
                .reduce((prev, current) => prev.add(current), BigNumber.from(0));
        }
    };

    const getAmountClaimable = async () => {
        if (account && library) {
            try {
                setLoading(true);
                setError("");
                const airdrops = new Contract(
                    deployment.address,
                    abi,
                    library.getSigner()
                ) as FSushiAirdropsVotingEscrow;
                return await airdrops.callStatic.claim(account);
            } catch (e) {
                if (e instanceof Error) setError(e.message);
            } finally {
                setLoading(false);
            }
        }
    };

    const claim = async () => {
        if (account && library) {
            try {
                setLoading(true);
                setError("");
                const airdrops = new Contract(
                    deployment.address,
                    abi,
                    library.getSigner()
                ) as FSushiAirdropsVotingEscrow;
                const tx = await airdrops.claim(account);
                const receipt = await tx.wait();
                setClaimed(true);
                return receipt.events?.find(e => e.event == "Claim") as ClaimEvent | undefined;
            } catch (e) {
                if (e instanceof Error) setError(e.message);
            } finally {
                setLoading(false);
            }
        }
    };

    return {
        queryTotalAmountClaimed,
        getAmountClaimable,
        claim,
        loading,
        error,
        claimed,
    };
};

export default useFSushiAirdropsVotingEscrow;
