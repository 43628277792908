import React, { useState } from "react";

import { useEthers } from "@usedapp/core";

import { API_SERVER } from "../constants";
import { ClaimParams } from "../hooks/useFSushiAirdrops";
import ErrorMessage from "./ErrorMessage";

const CheckEligibility = (props: { params?: ClaimParams; setParams: (p: ClaimParams) => void }) => {
    const { account, library } = useEthers();
    const [error, setError] = useState<string>();

    const checkEligibility = async () => {
        if (library) {
            try {
                const signer = library.getSigner();
                const timestamp = Math.floor(Date.now() / 1000);
                const message = "I want to verify myself as " + account + " (" + timestamp + ")";
                const hash = await signer.signMessage(message);

                const resp = await fetch(
                    API_SERVER +
                        `verify/60k?timestamp=${timestamp}&account=${account}&hash=${hash}&beneficiary=${account}`
                );
                const json = await resp.json();
                if (resp.ok) {
                    props.setParams(json);
                } else {
                    setError(json.errors[0].message);
                }
            } catch (e) {
                if (e instanceof Error) setError(e.message);
            }
        } else {
            alert("⚠️Your wallet doesn't support signing functionality.");
        }
    };

    return (
        <div className={"w-full"}>
            {props.params && !error ? (
                <button className={"w-full"} disabled={true}>
                    Eligible for claiming fSUSHI
                </button>
            ) : (
                <>
                    <button className={"w-full"} onClick={checkEligibility}>
                        Check your eligibility
                    </button>
                </>
            )}
            <ErrorMessage text={error} />
        </div>
    );
};

export default CheckEligibility;
