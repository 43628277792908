import React from "react";

import DepositFSushi from "../components/DepositFSushi";
import Menu from "../components/Menu";
import WithdrawFSushi from "../components/WithdrawFSushi";

const Staking = () => {
    return (
        <div>
            <Menu currentPath={"staking"} />
            <div className="container">
                <div className={"pane grid md:grid-cols-2 gap-6"}>
                    <div className={"card"}>
                        <div className={"heading"}>Deposit fSUSHI</div>
                        <div className={"my-4"}>
                            <p>
                                You can deposit <b>fSUSHI</b> into <b>xfSUSHI</b> to earn more. The more <b>fSUSHI</b>{" "}
                                for more weeks<i>(maximum 104)</i> you deposit, the more <b>fSUSHI</b> you get.
                            </p>
                            <p>
                                Also you can trade <b>xfSUSHI</b> in any market if you want!
                            </p>
                        </div>
                        <DepositFSushi />
                    </div>
                    <div className={"card"}>
                        <div className={"heading"}>Withdraw fSUSHI</div>
                        <div className={"my-4"}>
                            <p>
                                You can withdraw expired <b>fSUSHI</b> from <b>xfSUSHI</b>. While staking <b>fSUSHI</b>
                                it automatically accrues, so the amount you receive is always more than how much you
                                deposited.
                            </p>
                            <p>
                                But wait.. why would you want to withdraw instead of waiting and accumulating more
                                yield?
                            </p>
                        </div>
                        <WithdrawFSushi />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Staking;
